<template>
  <main class="main pa-2">
    <div class="profile-container pa-0 ma-0">
      <div class="row ma-0 profile_wrapper">
        <div class="col-md-4 col-lg-4 col-sm-12 profile_main">
          <div class="profile-user d-flex flex-column align-center pa-3 pt-11">
            <v-avatar size="123">
              <v-img
                :src="currentUser.photo
                    ? `https://admin.tumar.com/${currentUser.photo.match(/storage/) ? '':'storage'}/${currentUser.photo}`
                    : 'https://goferma.ru/wp-content/uploads/2017/03/i-8.jpg'"
                style="border: 3px solid #cfebcc"
              ></v-img>
            </v-avatar>
            <div class="user-info mb-4 d-flex flex-column align-center">
              <p class="user-name pa-5 mb-6">
                {{ currentUser.first_name || '' }}
                {{ currentUser.last_name || '' }}
              </p>
              <div class="container-fluid">
                <!-- <div class="columns"> </div> -->
              </div>
            </div>

            <BaseButton
              v-if="currentUser.role !== 'retail_client' && !isCraftsmanLogged"
              :title="this.$t('PROFILE.order_details')"
              @click="$router.push({ name: 'order details' })"
            />
          </div>
        </div>

        <div class="col-12 col-md-8 col-lg-8 col-sm-12">
          <div class="headline pa-4">
            <v-tabs v-model="tab" class="mb-12" hide-slider>
              <v-tab>{{ this.$t('PROFILE.settings_title') }}</v-tab>
              <template v-if="!isCraftsmanLogged">
                <v-tab v-if="currentUser?.role !== 'retail_client'">{{
                    this.$t('PROFILE.user_title')
                  }}</v-tab>
                <v-tab v-if="editingEmployee">{{
                    this.$t('PROFILE.edit_user')
                  }}</v-tab>
              </template>
            </v-tabs>

            <div v-if="tab === 0" class="user-form px-lg-0 px-md-0 px-sm-0">
              <div class="user-form__name mb-5">
                <input
                  type="text"
                  class="form-control mr-5"
                  :placeholder="this.$t('PROFILE.user_form[0]')"
                  v-model="first_name"
                />
                <input
                  type="text"
                  class="form-control"
                  :placeholder="this.$t('PROFILE.user_form[1]')"
                  aria-label="Username"
                  v-model="last_name"
                />
              </div>
              <div class="user-form__display mb-12">
                <input
                  type="file"
                  class="form-control mb-5"
                  :placeholder="this.$t('PROFILE.user_form[2]')"
                  aria-label="Username"
                  @change="setPhoto"
                  ref="file"
                />
                <input
                  type="text"
                  class="form-control mb-5"
                  :placeholder="this.$t('PROFILE.user_form[3]')"
                  aria-label="Username"
                  disabled
                  :value="fullName || ''"
                />
                <input
                  type="email"
                  class="form-control"
                  :placeholder="this.$t('PROFILE.user_form[4]')"
                  disabled
                  :value="currentUser.email"
                />
              </div>

              <div class="password mb-0 hauto">
                <h1 class="mb-7">{{ this.$t('PROFILE.change_password') }}</h1>

                <input
                  type="password"
                  class="form-control mb-5"
                  :placeholder="this.$t('PROFILE.password_change_form[0]')"
                />
                <input
                  type="password"
                  class="form-control mb-5"
                  :placeholder="this.$t('PROFILE.password_change_form[1]')"
                  v-model="newPassword"
                />
                <input
                  type="password"
                  class="form-control"
                  :placeholder="this.$t('PROFILE.password_change_form[2]')"
                  v-model="newPasswordConfirm"
                />
              </div>

              <div class="row no-gutters justify-end">
                <div class="col-5">
                  <BaseButton
                    @click="saveUpdatedProfile"
                    :disabled="formIsInvalid"
                    :title="this.$t('PROFILE.save_password')"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="tab === 1"
              class="user-form px-lg-0 px-md-10 px-sm-0 mb-5"
            >
              <div class="user-form__name mb-5">
                <input
                  type="text"
                  class="form-control mr-5"
                  :placeholder="this.$t('PROFILE.user_form[0]')"
                  aria-label="Username"
                  v-model="newEmployeeFirstName"
                />
                <input
                  type="text"
                  class="form-control"
                  :placeholder="this.$t('PROFILE.user_form[1]')"
                  aria-label="Username"
                  v-model="newEmployeeLastName"
                />
              </div>
              <div class="user-form__display mb-5">
                <!-- <input
                  type="text"
                  class="form-control mb-5"
                  :placeholder="this.$t('PROFILE.user_form[2]')"
                  aria-label="Username"
                /> -->
                <input
                  type="email"
                  class="form-control mb-5 email"
                  :placeholder="this.$t('PROFILE.user_form[3]')"
                  v-model="newEmployeeEmail"
                />
                <input
                  type="password"
                  class="form-control mb-5"
                  :placeholder="this.$t('PROFILE.user_form[5]')"
                  v-model="newEmployeePassword"
                />
                <select
                  class="form-control mb-5"
                  v-model="newEmployeeRole"
                  :class="{ 'grey--text': !newEmployeeRole }"
                >
                  <option value="" disabled selected>Роль</option>
                  <option value="admin">Администратор</option>
                  <option value="manager">Менеджер</option>
                </select>
              </div>

              <div class="row no-gutters justify-end">
                <div class="col-5">
                  <BaseButton :title="this.$t('PROFILE.add')" @click="submit" />
                </div>
              </div>
            </div>
            <div v-if="tab === 2" class="user-form px-10 mb-5">
              <div class="user-form__name mb-5">
                <input
                  type="text"
                  class="form-control mr-5"
                  :placeholder="this.$t('PROFILE.user_form[0]')"
                  aria-label="Username"
                  v-model="editingEmployee.first_name"
                />
                <input
                  type="text"
                  class="form-control"
                  :placeholder="this.$t('PROFILE.user_form[1]')"
                  aria-label="Username"
                  v-model="editingEmployee.last_name"
                />
              </div>
              <div class="user-form__display mb-5">
                <!-- <input
                  type="text"
                  class="form-control mb-5"
                  :placeholder="this.$t('PROFILE.user_form[2]')"
                  aria-label="Username"
                /> -->
                <input
                  type="email"
                  class="form-control mb-5 email"
                  :placeholder="this.$t('PROFILE.user_form[3]')"
                  v-model="editingEmployee.email"
                />
                <input
                  type="text"
                  class="form-control mb-5"
                  :placeholder="this.$t('PROFILE.user_form[4]')"
                  aria-label="Role"
                  v-model="editingEmployee.role"
                />
              </div>

              <div class="row no-gutters justify-end">
                <div class="col-5">
                  <BaseButton :title="this.$t('PROFILE.add')" @click="update" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="tab === 1" class="row ma-0">
        <div class="col-4"></div>
        <div class="col-md-8 col-lg-8 col-sm-12 overflow-x-auto">
          <div>
            <div class="list-users pa-2">
              <table class="table table-borderless">
                <thead class="list-users__data">
                  <tr>
                    <th
                      v-for="item in this.$t('PROFILE.user')"
                      :key="item"
                      scope="col"
                      >{{ item }}</th
                    >
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="employee in employees" :key="employee.id">
                    <td>{{ getEmployeeFullName(employee) }}</td>
                    <td>{{ employee.email }}</td>
                    <td>{{ employee.role }}</td>
                    <td>
                      <span class="edit">
                        <v-icon size="15">mdi-square-edit-outline</v-icon>
                      </span>
                      <a
                        class="text"
                        href="#"
                        @click.prevent="editEmployee(employee)"
                      >
                        {{ $t('PROFILE.edit') }}
                      </a>
                    </td>
                    <td>
                      <span class="edit">
                        <v-icon size="15">mdi-trash-can-outline</v-icon>
                      </span>
                      <a
                        class="text"
                        href="#"
                        @click.prevent="
                          currentUser.role === 'admin'
                            ? deleteEmployee(employee.id)
                            : () => {}
                        "
                      >
                        {{ $t('PROFILE.delete') }}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import axios from '@/core/services/api.service'

import { createNamespacedHelpers } from 'vuex'
import cloneDeep from 'lodash.clonedeep'
const { mapGetters, mapActions } = createNamespacedHelpers('auth')
const { mapActions: Actions_alerts } = createNamespacedHelpers('alerts')
const { mapState: State_employees, mapActions: Actions_employees } =
  createNamespacedHelpers('employees')
import BaseButton from './../layout/BaseButton.vue'
export default {
  name: 'Profile',
  components: { BaseButton },
  data() {
    return {
      tab: 0,
      first_name: '',
      last_name: '',
      photo: '',
      newPassword: '',
      newPasswordConfirm: '',

      newEmployeeFirstName: '',
      newEmployeeLastName: '',
      newEmployeeEmail: '',
      newEmployeePassword: '',
      newEmployeeRole: '',

      editingEmployee: null,
      baseUrl: this.$store.state.auth.API_URL,
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'isCraftsmanLogged']),
    ...State_employees(['employees']),
    fullName() {
      return this.currentUser.first_name + ' ' + this.currentUser.last_name
    },
    formIsInvalid() {
      let invalid = true
      if (this.newPassword) {
        invalid = false
      }
      if (
        this.first_name !== this.currentUser.first_name ||
        this.last_name !== this.currentUser.last_name ||
        this.photo !== this.currentUser.photo
      ) {
        invalid = false
      }

      return invalid
    },
  },
  watch: {
    currentUser: {
      immediate: true,
      handler(val) {
        if (val) {
          this.first_name = val.first_name
          this.last_name = val.last_name
          this.photo = val.photo
        }
      },
    },
    tab(val) {
      if (val !== 2 && this.editingEmployee) {
        this.editingEmployee = null
      }
    },
    editingEmployee(val) {
      if (!val) {
        this.tab = 1
      }
    },
  },
  methods: {
    ...mapActions(['updateProfile', 'fetchProfile', 'fetchCraftsmanProfile', 'updateCraftsmanProfile']),
    ...Actions_alerts(['addAlert']),
    ...Actions_employees([
      'fetchAllUsers',
      'deleteEmployee',
      'createEmployee',
      'updateEmployee',
    ]),
    uploadImage (image) {
      let form = new FormData()

      form.append('photos[]', image)

      return axios.post('/frontend/upload-photos', form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    deleteImage (url) {
      const payload = {filename: url}
      return axios.post('/frontend/upload-delete', payload)
    },
    async saveUpdatedProfile() {
      if (!this.formIsInvalid) {
        const {
          first_name,
          last_name,
          newPassword,
          newPasswordConfirm,
          photo,
        } = this
        if (newPassword !== newPasswordConfirm) {
          this.addAlert({ text: 'Пароли не сопадают', type: 'error' })
          this.newPasswordConfirm = ''
          return
        }

        const profile = {
          first_name: first_name || this.currentUser.first_name,
          last_name: last_name || this.currentUser.last_name,
          photo: photo || this.currentUser.photo,
        }
        if (this.newPasswordConfirm) {
          profile.password = this.newPasswordConfirm

          if (this.isCraftsmanLogged) {
            profile.password_confirmation = this.newPasswordConfirm
          }
        }

        if (this.isCraftsmanLogged) {
          profile.email = this.currentUser.email
          await this.updateCraftsmanProfile(profile)
        } else {
          await this.updateProfile(profile)
        }

        if (!this.isCraftsmanLogged) {
          this.fetchProfile()
        }

        this.addAlert({ text: 'Профиль обновлен', type: 'success' })
        this.newPassword = ''
        this.newPasswordConfirm = ''
      }
    },
    submit() {
      const body = {
        first_name: this.newEmployeeFirstName,
        last_name: this.newEmployeeLastName,
        email: this.newEmployeeEmail,
        role: this.newEmployeeRole,
        pasword: this.newEmployeePassword,
      }
      this.createEmployee(body)
      this.newEmployeeFirstName = ''
      this.newEmployeeLastName = ''
      this.newEmployeeEmail = ''
      this.newEmployeeRole = ''
      this.newEmployeePassword = ''
    },
    update() {
      const body = {
        first_name: this.editingEmployee.first_name,
        last_name: this.editingEmployee.last_name,
        email: this.editingEmployee.email,
        role: this.editingEmployee.role,
      }
      this.updateEmployee({ body, id: this.editingEmployee.id })
      this.editingEmployee = null
    },
    editEmployee(employee) {
      this.editingEmployee = cloneDeep(employee)
      this.tab = 2
    },

    getEmployeeFullName(employee) {
      return employee.first_name + ' ' + employee.last_name
    },
    async setPhoto(e) {
      if (this.isCraftsmanLogged) {
        const file = e.target.files[0];

        const res = await this.uploadImage(file)

        this.photo = res.data.files[0]

        if (this.currentUser.photo) {
          await this.deleteImage(this.currentUser.photo)
        }

      } else {
        const toBase64 = (file) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.readAsDataURL(file)
              reader.onload = () => resolve(reader.result)
              reader.onerror = (error) => reject(error)
            })

        const main = async () => {
          const file = this.$refs.file.files[0]
          this.photo = await toBase64(file)
        }

        await main()
      }
    },
  },
  created() {
    if (!this.isCraftsmanLogged) {
      this.fetchAllUsers()
    } else {
      this.fetchCraftsmanProfile()
    }
  },
}
</script>
<style lang="scss">
.v-avatar.mb-7 {
  width: 100%;
  background-size: cover;
}
nav.sidebar.v-navigation-drawer.v-navigation-drawer--fixed.v-navigation-drawer--floating.v-navigation-drawer--mini-variant.v-navigation-drawer--custom-mini-variant.v-navigation-drawer--open.theme--dark
  ~ header {
  width: 96% !important;
}
nav.sidebar.v-navigation-drawer.v-navigation-drawer--fixed.v-navigation-drawer--floating.v-navigation-drawer--custom-mini-variant.v-navigation-drawer--open.theme--dark
  ~ header {
  width: 84% !important;
}

.password.hauto {
  height: auto;
}

.profile-container {
  .v-tabs-bar {
    height: fit-content;
  }
  .v-slide-group__wrapper {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 1px;
      bottom: 0;
      background-color: #f2f2f2;
    }
  }
}
</style>
<style lang="scss" scoped>
.main {
  height: 100%;
  border: none;
}

.profile_wrapper {
  display: flex;
  @media (max-width: 665px) {
    flex-direction: column;
  }
  .profile_main {
    @media (max-width: 665px) {
      width: 100%;
    }
  }
}
.profile-container {
  margin-top: 21px;

  .v-tab {
    text-transform: unset;
    color: #c1c1c1 !important;
    font-family: 'Alegreya Sans';
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    padding: 11px 10px;
    box-sizing: content-box;
  }
  .v-tab--active {
    color: #666666 !important;
    position: relative;
    &:after {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      height: 1px;
      border-radius: 5px;
      bottom: 0;
      background-color: #666666;
    }
  }
}

.profile-user {
  background-color: #ffffff;
  border: 1px solid #ffffff;
}
.user-info {
  width: 100%;
}
.user-name {
  font-family: 'Roboto Condensed';
  color: #525350;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  border-bottom: 1px solid #f8f8f8;
  width: fit-content;
}

.columns {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}

.columns span {
  font-family: 'Alegreya Sans';
  font-size: 16px;
  color: #666666;
}

.columns__right span {
  font-family: 'Roboto Condensed';
  font-size: 18px;
}

.headline {
  background-color: #fff;
}

.user-form {
  .form-control {
    width: 100%;
  }
}
.user-form__name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .form-control {
    flex: 1;
  }
}

.user-form__name .form-control {
  height: 34px;
}

.form-control {
  background-color: #f6f6f6;
  font-size: 14px;
  font-family: 'Roboto Condensed';
}

.user-form__display .form-control {
  background-color: #f6f6f6;
  font-size: 14px;
  font-family: 'Roboto Condensed';
}

.password .form-control {
  height: 34px;
  background-color: #f6f6f6;
  font-size: 14px;
  font-family: 'Roboto Condensed';
}

.password h1 {
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 600;
  color: #31343c;
  letter-spacing: 1px;
}

.list-users {
  background-color: #fff;
  @media (max-width: 575px) {
    display: inline-block;
  }
}

.table {
  border-collapse: collapse;
  margin: 0;
  tbody tr {
    border-bottom: 1px solid #f5f5f5;
  }
  tr {
    &:not(:first-child):hover {
      background-color: #fbfbfb;
    }
    font-size: 14px;
    color: #423f3d;
  }
  td {
    font-family: 'Roboto Condensed';
    padding: 14px 12px;
  }
}

.list-users__data {
  background-color: #fbfbfb;
  font-family: 'Alegreya Sans';
  font-size: 14px;
  border: none;
}

td a {
  position: relative;
  padding-left: 28px;
  font-weight: 500;
}
a.text:active,
a.text:hover,
a.text {
  text-decoration: none;
  color: #423f3d;
}
.edit {
  position: absolute;
  height: 20px;
  width: 20px;
  background: #cfebcc;
  border-radius: 50%;
  border: 2px solid #cfebcc;
  display: flex;
  justify-content: center;
}

.icon_edit::before {
  content: '';
  background-repeat: no-repeat;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 3px;
  margin-left: 3px;
}
</style>
